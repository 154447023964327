import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TitleComponent {
  @ViewChild('titleContainer') span: ElementRef<HTMLSpanElement>;

  @Input() title: string = '';
  @Input() type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'caption' | 'subTitle' = null;
  @Input() dataCy: string = '';
  @Input() color: 'warn' | 'success' | 'primary' | 'caption' | 'blue' | 'violet' | 'orange' | 'gray' = 'primary';
  @Input() textAlign: 'center';
  @Input() required: boolean = false;
  @Input() oneLineView: boolean = false;
  @Input() capitalize: boolean = true;
  @Input() showLoaderOnEmpty: boolean = true;

  /**
   * For screen rotating and resizing
   */
  @HostListener('window:resize', ['$event'])
  onResize(_event: Event) {
    this.isEllipsisActive(this.span.nativeElement);
  }

  /**
   * Detection '...' in the title, if exists and oneLineView === true => show the tooltip with full title
   */
  isEllipsisActive(e: HTMLSpanElement): boolean {
    if (!this.oneLineView) {
      return false;
    }
    return e.offsetWidth < e.scrollWidth;
  }
}
