import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ManufacturerModel, PageableModel } from '@shared/models';
import { HttpHelperService } from './internal/http-helper.service';
import { ManufacturersPageableParams } from '@shared/models/build-models';
import { Observable, of, catchError, map, BehaviorSubject } from 'rxjs';
import { ApiService } from '@shared/classes/api-service';
import { TState } from '@shared/type/index.type';
import { IFilter } from '@shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ManufacturerService extends ApiService {
  private billedManufacturers$: BehaviorSubject<ManufacturerModel[]> = new BehaviorSubject<ManufacturerModel[]>(null);

  getBilledManufacturers(forceUpdate: boolean = false): Observable<ManufacturerModel[]> {
    if (!forceUpdate && this.billedManufacturers$.value !== null) {
      return of(this.billedManufacturers$.value);
    }
    const params: IFilter = { billAsOrg: true };
    return this.getPageable(new ManufacturersPageableParams(params)).pipe(
      map(data => {
        this.billedManufacturers$.next(data.content);
        return data.content;
      })
    );
  }

  deactivate(manufacturerId: string, state: TState): Observable<boolean> {
    return this.put<void>(`companies/${manufacturerId}/state?state=${state}`, {}).pipe(
      map(() => true),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getManufacturer(id: string, redirectToNotFoundPage: boolean = false): Observable<ManufacturerModel> {
    return this.get<ManufacturerModel>(`companies/${id}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        HttpHelperService.errorHandler(error, null, redirectToNotFoundPage);
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  toggleRequireSendSOForm(id: string, value: boolean): Observable<boolean> {
    return this.put<void>(`companies/${id}/require-send-sales-order-form?flag=${value}`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getPageable(params: ManufacturersPageableParams): Observable<PageableModel<ManufacturerModel>> {
    return this.get<PageableModel<ManufacturerModel>>(`companies/pageable`, HttpHelperService.addResponseTypeJSON(params));
  }

  createManufacturer(body: ManufacturerModel): Observable<string> {
    return this.post<string>(`companies`, body).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  updateManufacturer(id: string, body: ManufacturerModel): Observable<boolean> {
    return this.put<void>(`companies/${id}`, body).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  billAsOrg(id: string, billAsOrg: boolean): Observable<boolean> {
    return this.put<void>(`companies/${id}/billAsOrg?billAsOrg=${billAsOrg}`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  searchManufacturesInGudid(params: { name: string; size: number }): Observable<PageableModel<ManufacturerModel>> {
    return this.getGudid<PageableModel<ManufacturerModel>>(`companies`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        HttpHelperService.errorHandler(error, null);
        return of(null);
      })
    );
  }

  deleteContact(contactId: string): Observable<boolean> {
    return this.delete<void>(`companies/company-contacts/${contactId}`).pipe(
      map(() => true),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  destroy(): void {
    this.billedManufacturers$.next(null);
  }
}
