@if (allowRender()) {
  <router-outlet />
} @else {
  <div class="devGuardBg">
    <app-input-kit
      [dataCy]="'welcomePassword'"
      [required]="true"
      [type]="'password'"
      [initialValue]="password()"
      [label]="'Password'"
      (valueChangedEmitter)="password.set($event)"
      (enterEmitter)="checkPassword()"
    />
  </div>
}
