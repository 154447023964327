import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of, catchError, map } from 'rxjs';
import { LocationModel, PageableModel } from '@shared/models';
import { CreateLocationModel } from '@shared/models/request-models/create-location.model';
import { LocationsPageableParams } from '@shared/models/build-models/pageable/locations-pageable-params';
import { HttpHelperService } from './internal/http-helper.service';
import { ApiService } from '@shared/classes/api-service';

@Injectable({
  providedIn: 'root'
})
export class LocationService extends ApiService {
  getPageable(params: LocationsPageableParams): Observable<PageableModel<LocationModel>> {
    return this.get<PageableModel<LocationModel>>(`locations/pageable`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(new PageableModel());
      })
    );
  }

  getLocation(id: string): Observable<LocationModel> {
    return this.get<LocationModel>(`locations/${id}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  deactivate(id: string): Observable<boolean> {
    return this.put<void>(`locations/${id}/deactivate`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.deactivated');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  createLocation(body: CreateLocationModel): Observable<string> {
    return this.post<string>(`locations`, body).pipe(
      map(id => {
        this.alertsService.showSuccess('shared.alerts.successMessages.created');
        return id;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  updateLocation(id: string, body: LocationModel): Observable<boolean> {
    return this.put<void>(`locations/${id}`, body).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }
}
