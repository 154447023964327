import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validator, Validators } from '@angular/forms';
import { EventsService } from '@services/events.service';
import { UsersService } from '@services/users.service';
import { ProductsService } from '@services/products.service';
import { Router } from '@angular/router';
import { AlertsService } from '@services/internal/alerts.service';
import { CanAutoSaveDirective } from '@guards/autosave/can-auto-save';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { PermissionService } from '@services/internal/permission.service';
import { ChooseManufacturerModalComponent } from '../../event-detail/modals/choose-manufacturer-modal/choose-manufacturer-modal.component';
import { EventSaveRequestBuild } from '@shared/models/build-models/events/event-save-request-build';
import { IEventBuildParams } from '@shared/interfaces';
import { BehaviorSubject, finalize, firstValueFrom, map, Observable, take, takeUntil } from 'rxjs';
import { EventsStoreService } from '../../../services/events-store.service';
import { TEventType, TRoles } from '@shared/type/index.type';
import {
  EventQuoteModel,
  ContactModel,
  InventoryModel,
  ManufacturerModel,
  ProcedureModel,
  ProductLineModel,
  UserModel,
  UserTimeZone
} from '@shared/models';
import { ConfirmModalConfig, CreateSmallEntityConfig } from '@constants';
import { CreateProductComponent } from 'src/app/features/inventory/products/partials/modals/create-product-line/create-product.component';
import { MatStepper } from '@angular/material/stepper';
import { ManufacturerService } from '@services/manufacturer.service';
import { ConfirmComponent } from '@shared/components/modals/confirm/confirm.component';
import { LanguageService } from '@services/internal/language.service';
import { UserAssignmentsService } from '@services/user-assignments.service';
import { EventQuoteParamsModel } from '@shared/models/features/events/event-quote.model';
import { EventQuotesService } from '@services/event-quotes.service';
import { IAIEventCreation } from '@shared/interfaces/events/ai-event-creation';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateEventComponent extends CanAutoSaveDirective implements OnInit {
  @ViewChild('stepper') stepperRef: MatStepper;

  allowEdit$: Observable<boolean> = this.eventsStoreService.store$.pipe(map(st => st.allowEdit));
  assignments: UntypedFormGroup;
  assignmentsValidator: any = null;
  filterByRepresentative: boolean = true;
  permittedRepresentative: UserModel;
  required: UntypedFormGroup;
  selectedCustody: UserModel;
  selectedProducts: ProductLineModel[] = [];
  selectedContainers: InventoryModel[] = [];
  selectedFacilityContact: ContactModel[] = [];
  selectedManufacturers: Observable<ManufacturerModel[]> = this.eventsStoreService.store$.pipe(map(st => st.manufacturers));
  showFilterProducts: boolean = false;
  formsValid$: Observable<boolean> = this.eventsStoreService.store$.pipe(map(s => s.formsValid));
  isCreatePage: boolean = true;
  canSeeAutocompleteIcons: boolean = true;
  processing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  modifiers$: Observable<{ modifierName: string }[]> = this.eventsStoreService.store$.pipe(map(st => st.modifiers));
  predefinedEventType$: BehaviorSubject<TEventType> = new BehaviorSubject<TEventType>('CASE');
  readonly userTimeZone: UserTimeZone;
  private manufacturersExist: boolean;

  constructor(
    public eventsService: EventsService,
    public manufacturerService: ManufacturerService,
    public userAssignmentsService: UserAssignmentsService,
    public productsService: ProductsService,
    public dialog: MatDialog,
    public alertsService: AlertsService,
    public router: Router,
    public permissionService: PermissionService,
    public ref: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
    public eventsStoreService: EventsStoreService,
    @Optional() public dialogRef: MatDialogRef<CreateEventComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      dateTime: Date;
      setTime: boolean;
      excludeEventTypes?: TEventType[];
      selectedEventTypeOnCreate?: TEventType;
      showOpenInNewIconOnCreateEvent?: boolean;
      aAIEventCreationData?: IAIEventCreation;
    },
    public eventQuotesService: EventQuotesService
  ) {
    super();
    if (data?.showOpenInNewIconOnCreateEvent === false) {
      this.canSeeAutocompleteIcons = false;
    }
    this.userTimeZone = UsersService.userTimeZone;
  }

  ngOnInit() {
    if (this.isCreatePage) {
      this.eventsStoreService.clearState();
      if (this.data?.selectedEventTypeOnCreate) {
        this.predefinedEventType$.next(this.data.selectedEventTypeOnCreate);
        return;
      }
      // Get prediction for event type from Big Query
      this.eventsStoreService
        .getEventTypePredictions()
        .pipe(take(1))
        .subscribe(type => {
          this.predefinedEventType$.next(type || 'CASE');
        });
    }

    if (this.permissionService.isGranted('events', 'canCreateEventForSelfOnly')) {
      this.permittedRepresentative = UsersService.getUser();
      this.ref.markForCheck();
    }

    // Check Manufacturers added for autosave
    this.eventsStoreService.store$
      .pipe(
        map(st => st.manufacturers),
        takeUntil(this.subscriptions)
      )
      .subscribe(manufacturers => {
        this.manufacturersExist = Boolean(manufacturers.length);
      });
  }

  async addProductLine(eventId?: string): Promise<void> {
    const selectedManufacturers: ManufacturerModel[] = this.eventsStoreService.store$.value.manufacturers;
    if (selectedManufacturers.length === 1) {
      await this.createProductLine(selectedManufacturers[0], eventId);
      return;
    }
    const dialogConfig: MatDialogConfig = { ...CreateSmallEntityConfig };
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      manufacturers: selectedManufacturers,
      allowSelectAll: false,
      allowUsingBilledManufacturers: true
    };

    const dialogRefChooseManufacturer = this.dialog.open(ChooseManufacturerModalComponent, dialogConfig);

    const manufacturerIds: string[] = await firstValueFrom(dialogRefChooseManufacturer.afterClosed());
    if (manufacturerIds?.length) {
      this.manufacturerService
        .getManufacturer(manufacturerIds[0])
        .pipe(take(1))
        .subscribe(async manufacturer => {
          await this.createProductLine(manufacturer, eventId);
        });
    }
  }

  assignmentsValidatorUpdate(validator: Validator): void {
    this.assignmentsValidator = validator;
  }

  async assignProducts(id: string): Promise<void> {
    const dialogRef = this.dialog.open(
      ConfirmComponent,
      ConfirmModalConfig({
        description: LanguageService.instant('shared.alerts.prompt.addProductToEvent')
      })
    );

    const result: boolean = await firstValueFrom(dialogRef.afterClosed());
    if (result) {
      await this.eventsStoreService.assignProducts(id);
      this.eventsStoreService.getProductLines();
    }
  }

  async assignContainer(containers: InventoryModel[], eventId: string): Promise<void> {
    const containerIds: string[] = containers.map(container => container.id);
    await firstValueFrom(this.eventsService.assignContainers(eventId, containerIds));
    this.eventsStoreService.loadContainers();
  }

  autoSave(): void {
    this.submit(false);
  }

  autoSaveFormsCheckValid(): UntypedFormGroup[] {
    let assignments = this.assignments;
    let required = this.required;
    if (!this.manufacturersExist) {
      assignments = this.formBuilder.group({ manufacturerIds: [null, [Validators.required]] });
    }
    if (this.required.get('facilityId')?.value?.isCreditHold) {
      required = this.formBuilder.group({ facilityId: [null, [Validators.required]] });
      required.markAsTouched();
      required.markAsDirty();
    }
    if (this.required.get('eventType')?.value === 'STOCK_ADJUSTMENT') {
      return [this.required];
    } else {
      return [required, assignments];
    }
  }

  checkValidation(): void {
    setTimeout(() => {
      const roleName: TRoles = this.required?.get('representativeId')?.value?.role;
      const eventType: TEventType = this.required.get('eventType')?.value;
      this.eventsStoreService.store$.next({
        ...this.eventsStoreService.store$.value,
        ...{ formsValid: this.required.valid && (eventType === 'STOCK_ADJUSTMENT' ? true : this.assignments?.valid) }
      });
      this.eventsStoreService.store$.next({
        ...this.eventsStoreService.store$.value,
        ...{ formsTouched: this.required.touched || this.assignments?.touched }
      });
      this.eventsStoreService.store$.next({ ...this.eventsStoreService.store$.value, ...{ required: this.required } });
      this.eventsStoreService.store$.next({ ...this.eventsStoreService.store$.value, ...{ assignments: this.assignments } });
      this.showFilterProducts = roleName === 'SALES' || roleName === 'DISTRIBUTOR';
      this.ref.markForCheck();
    }, 50);
  }

  async removeManufacturer(id: string): Promise<void> {
    const selectedManufacturers: ManufacturerModel[] = this.eventsStoreService.store$.value.manufacturers;
    const index: number = selectedManufacturers.findIndex(m => m.id === id);
    selectedManufacturers.splice(index, 1);
    this.eventsStoreService.store$.next({ ...this.eventsStoreService.store$.value, ...{ manufacturers: selectedManufacturers } });
    this.selectedProducts = [];
    this.ref.detectChanges();
  }

  removeModifier(item: { modifierName: string }): void {
    const modifiers: { modifierName: string }[] = this.eventsStoreService.store$.value.modifiers;
    const index: number = modifiers.indexOf(item);

    if (index >= 0) {
      modifiers.splice(index, 1);
    }

    this.eventsStoreService.store$.next({ ...this.eventsStoreService.store$.value, ...{ modifiers } });
    this.ref.detectChanges();
  }

  removeFacilityContact(contactIds: string[]): void {
    contactIds.forEach(id => {
      const index: number = this.selectedFacilityContact.findIndex(contact => contact.id === id);
      this.selectedFacilityContact.splice(index, 1);
    });
    this.ref.detectChanges();
  }

  removeProduct(productId: string): void {
    const index: number = this.selectedProducts.findIndex(prod => prod.id === productId);
    this.selectedProducts.splice(index, 1);
    this.ref.detectChanges();
  }

  removeContainer(containerId: string): void {
    const index: number = this.selectedContainers.findIndex(container => container.id === containerId);
    this.selectedContainers.splice(index, 1);
    this.ref.detectChanges();
  }

  async submit(redirect = true, quote?: EventQuoteModel): Promise<void> {
    this.processing$.next(true);

    const selectedManufacturers: ManufacturerModel[] = await firstValueFrom(this.selectedManufacturers);
    const buildParams: IEventBuildParams = {
      selectedFacilityContacts: this.selectedFacilityContact,
      selectedProducts: this.selectedProducts,
      selectedManufacturers,
      required: this.required,
      assignments: this.assignments,
      userTimeZone: this.userTimeZone,
      redirect,
      modifiers: this.eventsStoreService.store$.value.modifiers
    };
    const params: EventSaveRequestBuild = new EventSaveRequestBuild(buildParams);
    const procedure: ProcedureModel = this.required.get('procedureId').value;
    const newModifiers: string[] = this.eventsStoreService.getUniqueModifiers(procedure);

    if (params.eventType === 'CASE' && !params.name) {
      const eventDatetime: Date = new Date(params.datetime);
      const eventYear: string = eventDatetime.getFullYear().toString();
      const eventDate: string = ('0' + eventDatetime.getDate()).slice(-2);
      const eventMonth: string = ('0' + (eventDatetime.getMonth() + 1)).slice(-2);
      const physicianName = this.required.get('physicianId').value?.fullName
        ? this.required.get('physicianId').value?.fullName + ' - '
        : '';

      params.name = eventYear + eventMonth + eventDate + ' - ' + physicianName + this.required.get('facilityId').value.name;
    }

    this.eventsService
      .createEvent(params)
      .pipe(
        take(1),
        finalize(() => this.processing$.next(false))
      )
      .subscribe(id => {
        if (!id) {
          return;
        }

        // Duplicate quote during the event duplication
        if (quote) {
          this.createQuote(id, quote);
        }

        // Automatically create a quote if a preference card is added (CC-4369)
        if (params.preferenceCardId && !quote && (params.eventType === 'CASE' || params.eventType === 'STOCKING_ORDER')) {
          this.eventsStoreService.createQuoteFromPreferenceCard(id, params.preferenceCardId);
        }

        if (this.selectedContainers.length) {
          this.assignContainer(this.selectedContainers, id);
        }

        this.required.reset();
        this.assignments.reset();

        if (this.data?.excludeEventTypes?.length) {
          this.dialogRef.close(id);
          return;
        }

        if (redirect) {
          this.dialogRef.close({ success: true, eventId: id });
          this.eventsStoreService.clearState();
          this.router.navigate([`/events/edit/${id}`]);
        }

        this.eventsStoreService.store$.next({ ...this.eventsStoreService.store$.value, ...{ createRequestFromRecipe: true } });

        if (newModifiers.length) {
          this.eventsStoreService.updateProcedureModifiers(procedure, newModifiers);
        }
      });
  }

  private async createProductLine(manufacturer: ManufacturerModel, eventId?: string): Promise<void> {
    // Load the module dynamically
    await import('../../../../../inventory/products/product.module').then(p => p.ProductModule);
    const dialogConfig: MatDialogConfig = { ...CreateSmallEntityConfig };
    dialogConfig.data = {
      manufacturer,
      showAssignToCustodyCheckbox: this.required.get('representativeId')?.value?.role === 'SALES',
      redirect: false
    };
    const dialogRef = this.dialog.open(CreateProductComponent, dialogConfig);

    const params = await firstValueFrom(dialogRef.afterClosed());
    if (params?.productLineId?.length) {
      const { productLineId, assign } = params;
      const res: ProductLineModel = await firstValueFrom(this.productsService.getProduct(productLineId));
      this.selectedProducts.push(res);
      this.selectedProducts = [...[], ...this.selectedProducts];
      if (eventId) {
        this.assignProducts(res.id).then();
      }

      const custodyId: string = this.required.get('representativeId').value?.id;
      if (assign && custodyId?.length) {
        const assignProducts = await firstValueFrom(this.userAssignmentsService.assignProduct(custodyId, [productLineId]));
        if (assignProducts) {
          this.filterByRepresentative = true;
          this.alertsService.showSuccess(null, `Product line has been assigned to ${this.required.get('representativeId').value.name}`);
        }
      } else {
        this.filterByRepresentative = false;
      }
      this.ref.detectChanges();
    }
  }

  createQuote(id: string, quote: EventQuoteModel): void {
    const quoteCatalogs: { catalogId: string; quantity: number }[] = quote.catalogQuoteQuantities.map(c => ({
      catalogId: c.catalog.id,
      quantity: c.quantity
    }));
    const params: EventQuoteParamsModel = {
      eventId: id,
      note: quote.eventQuoteNote,
      catalogQuoteQuantities: quoteCatalogs
    };

    this.eventQuotesService.createQuote(params).pipe(take(1)).subscribe();
  }
}
