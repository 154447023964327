import { TEventType } from '@shared/type/index.type';

export class EventTemplateBuilder {
  name?: boolean;
  representative?: boolean;
  date?: boolean;
  time?: boolean;
  procedure?: boolean;
  physician?: boolean;
  facility?: boolean;
  facilityContact?: boolean;
  description?: boolean;
  protocol?: boolean;
  investigator?: boolean;
  manufacturers?: boolean;
  productLine?: boolean;
  containers?: boolean;
  anonymousPatientId?: boolean;
  preferenceCard?: boolean;

  constructor(type?: TEventType) {
    return EventTemplateBuilder.build(type);
  }

  private static build(type: TEventType): EventTemplateBuilder {
    switch (type) {
      case 'CASE':
        return {
          name: true,
          representative: true,
          date: true,
          manufacturers: true,
          productLine: true,
          procedure: true,
          physician: true,
          facility: true,
          time: true,
          containers: true,
          anonymousPatientId: true,
          preferenceCard: true
        };
      case 'DAMAGE':
        return {
          name: true,
          representative: true,
          date: true,
          manufacturers: true,
          productLine: true,
          physician: true,
          facility: true,
          containers: true,
          anonymousPatientId: true
        };
      case 'LOAN':
      case 'SAMPLE':
        return {
          name: true,
          representative: true,
          date: true,
          manufacturers: true,
          productLine: true,
          physician: true,
          facility: true,
          containers: true,
          anonymousPatientId: true,
          preferenceCard: true
        };
      case 'OTHER':
        return {
          name: true,
          representative: true,
          date: true,
          manufacturers: true,
          productLine: true,
          description: true,
          time: true,
          physician: true,
          facility: true,
          containers: true,
          anonymousPatientId: true,
          preferenceCard: true
        };
      case 'RETURN':
        return {
          name: true,
          representative: true,
          date: true,
          manufacturers: true,
          productLine: true,
          protocol: true,
          containers: true,
          anonymousPatientId: true,
          facility: true
        };
      case 'STOCKING_ORDER':
        return {
          name: true,
          representative: true,
          date: true,
          manufacturers: true,
          productLine: true,
          facility: true,
          facilityContact: true
        };
      case 'STOCK_ADJUSTMENT':
        return {
          name: true,
          date: true,
          containers: true
        };
      case 'TRIAL':
        return {
          name: true,
          representative: true,
          date: true,
          manufacturers: true,
          productLine: true,
          protocol: true,
          investigator: true,
          procedure: true,
          time: true,
          physician: true,
          facility: true,
          containers: true,
          anonymousPatientId: true,
          preferenceCard: true
        };
      default:
        return {
          name: true,
          date: true,
          representative: true,
          manufacturers: true,
          productLine: true
        };
    }
  }
}
