import { TEventPaidStatus, TEventType, TSalesOrderStatus } from '@shared/type/index.type';
import { IEventBuildParams } from '@shared/interfaces';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export class EventSaveRequestBuild {
  commissionPaid: TEventPaidStatus;
  datetime: Date | string;
  description: string;
  eventStatus?: string;
  eventType: TEventType;
  facilityContactIds?: string[];
  facilityId: string;
  healthRecordId: string;
  invoicePaid: TEventPaidStatus;
  manufacturerIds: string[];
  modifiers: string[];
  name: string;
  physicianIds: string[];
  preferenceCardId: string;
  principalInvestigator: string;
  procedureId: string;
  processedStatus: TSalesOrderStatus;
  productIds: string[];
  protocolNumber: string;
  representativeId: string;

  constructor(params: IEventBuildParams) {
    return EventSaveRequestBuild.buildRequestFromForms(params);
  }

  public static buildRequestFromEventData({
    selectedFacilityContacts,
    selectedProducts,
    selectedManufacturers,
    commissionPaid,
    invoicePaid,
    processedStatus,
    eventData,
    modifiers
  }: IEventBuildParams) {
    const facilityContactIds: string[] = selectedFacilityContacts?.map(c => c.id);
    const productIds: string[] = selectedProducts.map(prod => prod.id);
    const manufacturers: string[] = selectedManufacturers.map(m => m.id);
    const modifiersArray: string[] = modifiers.map(modifier => modifier.modifierName) || [];
    const param: EventSaveRequestBuild = {
      description: eventData.description,
      healthRecordId: eventData.healthRecordId,
      principalInvestigator: eventData.principalInvestigator,
      protocolNumber: eventData.protocolNumber,
      name: eventData.name,
      representativeId: eventData.representative?.id,
      procedureId: eventData.procedure?.id,
      preferenceCardId: eventData.preferenceCard?.id,
      physicianIds: [eventData.physicians[0]?.id],
      modifiers: modifiersArray,
      facilityId: eventData.facility?.id,
      datetime: eventData.datetime,
      eventType: eventData.eventType,
      manufacturerIds: manufacturers,
      productIds,
      commissionPaid,
      invoicePaid,
      processedStatus,
      facilityContactIds
    };
    return param;
  }

  private static buildRequestFromForms({
    selectedFacilityContacts,
    selectedProducts,
    selectedManufacturers,
    required,
    assignments,
    userTimeZone,
    redirect,
    processedStatus,
    commissionPaid,
    invoicePaid,
    modifiers
  }: IEventBuildParams): EventSaveRequestBuild {
    let datetime = null;
    if (required.get('date').value) {
      let date = utcToZonedTime(zonedTimeToUtc(required.get('date').value, userTimeZone.timeZone), userTimeZone.timeZone);

      if (required.get('time').value?.length) {
        const hours = +required.get('time').value.split(':')[0];
        const minutes = +(required.get('time').value.split(':')[1] || 0);
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(0);
        date.setMilliseconds(0);
        date = zonedTimeToUtc(date, userTimeZone.timeZone);
      } else {
        date = zonedTimeToUtc(date, userTimeZone.timeZone);
        date.setUTCHours(12);
        date.setUTCMinutes(0);
        date.setUTCSeconds(0);
        date.setUTCMilliseconds(15);
      }

      datetime = date.toISOString();
    }

    const facilityContactIds: string[] = selectedFacilityContacts?.map(c => c.id);
    const productIds: string[] = selectedProducts.map(prod => prod.id);
    const manufacturers: string[] = selectedManufacturers.map(m => m.id);
    const modifiersArray: string[] = modifiers.map(modifier => modifier.modifierName) || [];
    const physicianIds: string[] = required.get('physicianId').value?.id ? [required.get('physicianId').value?.id] : [];
    const param: EventSaveRequestBuild = {
      description: '',
      healthRecordId: '',
      principalInvestigator: '',
      protocolNumber: '',
      name: required.value.name,
      representativeId: required.get('representativeId').value ? required.get('representativeId').value.id : null,
      procedureId: required.get('procedureId').value ? required.get('procedureId').value.id : null,
      physicianIds: physicianIds,
      facilityId: required.get('facilityId').value ? required.get('facilityId').value.id : null,
      datetime,
      eventType: required.get('eventType').value,
      manufacturerIds: manufacturers,
      preferenceCardId: assignments.get('preferenceCard').value ? assignments.get('preferenceCard').value.id : null,
      productIds,
      commissionPaid,
      invoicePaid,
      processedStatus,
      facilityContactIds,
      modifiers: modifiersArray
    };
    if (redirect) {
      param.eventStatus = 'OPEN';
    }
    if (required.get('description').value) {
      param.description = required.get('description').value;
    }
    if (required.get('principalInvestigator').value) {
      param.principalInvestigator = required.get('principalInvestigator').value;
    }
    if (required.get('protocolNumber').value) {
      param.protocolNumber = required.get('protocolNumber').value;
    }
    if (required.value.healthRecordId && required.value.healthRecordId.length) {
      param.healthRecordId = required.value.healthRecordId;
    }
    return param;
  }
}
