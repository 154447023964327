import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { AuthenticationService } from '@services/auth.service';
import { AlertsService } from '@services/internal/alerts.service';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  from,
  fromEvent,
  mergeMap,
  Observable,
  of,
  take,
  takeUntil,
  tap,
  timer,
  map,
  finalize
} from 'rxjs';
import { DestroySubscriptions } from '@shared/classes/destroy-subscriptions';
import { IFilter } from '@shared/interfaces';
import { NotificationsPageableParams } from '@shared/models/build-models';
import { MessagingNotificationModel, MessagingNotificationParams, NotificationModel, UserModel } from '@shared/models';
import { NotificationsService } from '@services/notifications.service';
import { Router } from '@angular/router';
import { GoogleApiService } from '@shared/modules/maps/services/google-api.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LocalStorage } from '@services/internal/localstorage.service';
import { UsersService } from '@services/users.service';
import { PushNotificationsService } from '@services/push-notifications.service';
import { OfflineStatusService } from '@services/offline/offline-status.service';
import { GlobalStoreService } from '@services/internal/global-store.service';
import { PermissionService } from '@services/internal/permission.service';
import { FindLinkInString } from '@shared/utils/find-link-in-string';
import { GetStatisticLink } from '../../../../features/notifications/utils/get-statistic-route';
import { GetDifferenceInBusinessDays } from '@shared/utils/date/get-diff-days';
import { AuditsService } from '@services/audits.service';
import { CreateSmallEntityConfig } from '@constants';

declare let pendo: any;
let context: LayoutsComponent;

@Component({
  selector: 'app-layouts',
  templateUrl: './layouts.component.html',
  styleUrls: ['./layouts.component.scss']
})
export class LayoutsComponent extends DestroySubscriptions implements OnInit, OnDestroy {
  private offlineStatusService = inject(OfflineStatusService);
  private authenticationService = inject(AuthenticationService);
  private alertService = inject(AlertsService);
  private notificationsService = inject(NotificationsService);
  private router = inject(Router);
  private googleApiService = inject(GoogleApiService);
  private dialogRef = inject(MatDialog);
  private pushNotificationsService = inject(PushNotificationsService);
  private globalStoreService = inject(GlobalStoreService);
  private permissionService = inject(PermissionService);
  private auditsService = inject(AuditsService);

  hideSubNav: boolean = false;
  toggleSidebar: boolean;
  showDirectoryRoutes: boolean = false;
  showInventoryRoutes: boolean = false;
  showMovementRoutes: boolean = false;
  showBurgerMenu: boolean = false;
  totalElements$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  userName$: Observable<string> = UsersService.user$.pipe(
    map((u: UserModel) => u.name),
    distinctUntilChanged(),
    tap((name: string) => {
      setTimeout(() => {
        const namesArray = name.split(' ');
        if (namesArray.length > 1) {
          this.nameInitials$.next(namesArray[0].slice(0, 1) + namesArray[1].slice(0, 1));
        } else {
          this.nameInitials$.next(name.slice(0, 2));
        }
      });
    })
  );
  organizationName$: Observable<string> = UsersService.user$.pipe(map(u => u.organization.company.name));
  nameInitials$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  messagingNotifications: MessagingNotificationModel[] = [];
  totalMessagingNotifications: number = 0;
  loadingMessagingNotifications$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showAuditState$: BehaviorSubject<boolean> = AuditsService.doAuditsMatchingStatusExist;
  readonly isMobile$: BehaviorSubject<boolean> = GlobalStoreService.isMobile$;
  readonly isOffline$: BehaviorSubject<boolean> = this.offlineStatusService.isOffline$;
  // 75 minutes without any actions
  private timeout: number = 4500000;
  private timer: number;

  constructor() {
    super();
    context = this;
    this.googleApiService.loadGoogleMapsAPI().pipe(takeUntil(this.subscriptions)).subscribe();
  }

  /**
   * Init Pendo
   * https://app.pendo.io/setup?setupKey=
   * eyJTdWJzY3JpcHRpb25OYW1lIjoiQ29ubmVjdFN4X19MTEMiLCJIbWFjIjoiYS1Yb0pVSDVDMUhkbTY1Rm5IV0s1VGNTQ1RuOXRvOXdUT29RMUd5TEpERT0ifQ&os=web
   */
  static initPendo(): void {
    // @ts-ignore
    if (location.hostname === 'localhost' || Boolean(window?.Cypress)) {
      return;
    }
    of(pendo)
      .pipe(take(1))
      .subscribe(() => {
        const user = UsersService.getUser();
        const platform = GlobalStoreService.getPlatform();
        if (!user?.id?.length) {
          user.emailAddress = 'unauthorized.user';
          user.role = 'SALES';
          user.name = 'UNAUTHORIZED';
        }
        pendo.initialize({
          visitor: {
            id: user.id, // Required if user is logged in
            full_name: user.name,
            role: user.role,
            colorTheme: user.colorTheme,
            Org: user.organization?.company?.name,
            platform
          },
          account: {
            id: user.id, // Required if using Pendo Feedback
            email: user.emailAddress,
            full_name: user.name,
            role: user.role,
            colorTheme: user.colorTheme,
            Org: user.organization?.company?.name,
            platform
          }
        });
      });
  }

  ngOnInit(): void {
    this.toggleSidebar = LocalStorage.getWebStorageItem('isNavbarExpanded') === 'true';
    /** Load notifications */
    timer(2000, 120000)
      .pipe(takeUntil(this.subscriptions))
      .subscribe(() => {
        this.getNotifications();
        this.getMessagingNotifications();
      });

    if (GlobalStoreService.getPlatform() === 'web') {
      LocalStorage.setItem('idleTimeout', JSON.stringify(new Date().getTime()));

      this.resetIdleTimer();

      from(['click', 'keyup', 'wheel'])
        .pipe(mergeMap(event => fromEvent(document, event).pipe(debounceTime(5000))))
        .pipe(takeUntil(this.subscriptions))
        .subscribe(() => {
          LocalStorage.setItem('idleTimeout', JSON.stringify(new Date().getTime()));
          this.resetIdleTimer();
        });
    } else {
      this.pushNotificationsService.init();
    }

    LayoutsComponent.initPendo();

    timer(2500, 180000)
      .pipe(takeUntil(this.subscriptions))
      .subscribe(() => this.auditsService.getAuditMatchingStatus());
  }

  ngOnDestroy(): void {
    this.globalStoreService.clearStore();
    this.unSubscribe();
    this.dialogRef.closeAll();
    LocalStorage.removeItem('idleTimeout');
    if (typeof pendo?.clearSession === 'function') {
      pendo.clearSession();
    }
    // Remove notification badge favicon
    this.totalElements$.next(0);
    this.setFaviconNotification();
  }

  isActive = (urls: string[]): boolean => urls.some((url: string) => this.router.url.includes(url));

  logout(): void {
    this.authenticationService.logout();
  }

  toggleScrollableProperty(menuOpened: boolean): void {
    if (menuOpened) {
      document.body.classList.add('nonScrollableBlock');
    } else {
      document.body.classList.remove('nonScrollableBlock');
    }
  }

  static forceGetNotifications(): void {
    context.getNotifications();
  }

  static forceGetMessagingNotifications(): void {
    context.getMessagingNotifications();
  }

  getNotifications(): void {
    if (!this.permissionService.isGranted('notifications', 'readNotifications')) {
      return;
    }

    const params: IFilter = {
      isRead: false,
      page: 0,
      size: 1
    };

    this.notificationsService
      .getPageable(new NotificationsPageableParams(params))
      .pipe(take(1))
      .subscribe(data => {
        this.totalElements$.next(data?.totalElements || 0);
        this.setFaviconNotification();
        this.checkJobNotification(data.content[0]);
      });
  }

  getMessagingNotifications(): void {
    if (!this.permissionService.isGranted('notifications', 'readNotifications')) {
      return;
    }

    this.loadingMessagingNotifications$.next(true);

    const params: MessagingNotificationParams = {
      recipientId: UsersService.getUser().id,
      alertSubject: 'EVENT_COMMENT_ADDED',
      excludeAlertSubjects: []
    };

    this.notificationsService
      .getMessagingNotifications(params)
      .pipe(
        take(1),
        finalize(() => this.loadingMessagingNotifications$.next(false))
      )
      .subscribe(data => {
        this.messagingNotifications = data.filter(
          notification => notification.unreadCount || GetDifferenceInBusinessDays(notification.alertNotification.createdDatetime) <= 2
        );
        this.totalMessagingNotifications = data.reduce((accumulator: number, currentValue) => accumulator + currentValue.unreadCount, 0);
      });
  }

  checkJobNotification(data: NotificationModel): void {
    if (UsersService.getUser()?.id === data?.recipient?.id) {
      if (
        data.entityType === 'STATISTICS' &&
        NotificationsService.checkReportJobNotification$.value &&
        data.subject !== 'Overdue inventory' &&
        data.subject !== 'Expired inventory' &&
        data.subject !== 'Expired inspection'
      ) {
        this.alertService.showSuccess('shared.alerts.successMessages.reportFinished', null, 10000, 'Open', () => {
          this.router.navigate([GetStatisticLink(data.message)]);
          NotificationsService.checkReportJobNotification$.next(false);
        });
      } else if (
        data.entityType === 'INVENTORY_IMPORT' &&
        data.subject === 'Inventory Import Done' &&
        NotificationsService.checkImportJobNotification$.value
      ) {
        this.alertService.showSuccess('shared.alerts.successMessages.importFinished', null, 10000, 'Open', () =>
          this.router.navigate(['/inventory/import/detail/' + data.entityId])
        );
        NotificationsService.checkImportJobNotification$.next(false);
      } else if (
        data.entityType === 'INVENTORY_EXPORT' &&
        data.subject === 'Inventory Export Done' &&
        NotificationsService.checkExportJobNotification$.value
      ) {
        this.alertService.showSuccess('shared.alerts.successMessages.exportFinished', null, 10000, 'Download', () =>
          window.open(FindLinkInString(data.message), '_self')
        );
        NotificationsService.checkExportJobNotification$.next(false);
      }
    }
  }

  saveToggle(value: boolean): void {
    LocalStorage.setWebStorageItem('isNavbarExpanded', String(value));
  }

  async openAIFeaturesListModal(): Promise<void> {
    const dialogConfig: MatDialogConfig = { ...CreateSmallEntityConfig };
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = false;
    const aiFeaturesListComponent = await import('../../modals/ai-features-list/ai-features-list.component').then(
      c => c.AiFeaturesListComponent
    );
    this.dialogRef.open(aiFeaturesListComponent, dialogConfig);
  }

  private setFaviconNotification(): void {
    const favicon: HTMLElement = document.getElementById('favicon');
    const faviconPath: string =
      this.totalElements$.value > 0 ? 'assets/img/icons/icon-36x36-notification.png' : 'assets/img/icons/icon-36x36.png';
    favicon.setAttribute('href', faviconPath);
  }

  private resetIdleTimer(): void {
    clearTimeout(this.timer);
    this.timer = window.setTimeout(this.logoutIdleUser.bind(this), this.timeout);
  }

  private async logoutIdleUser(): Promise<void> {
    const idle = await LocalStorage.getItem('idleTimeout');
    if (new Date().getTime() - JSON.parse(idle) < this.timeout) {
      this.resetIdleTimer();
      return;
    }
    this.authenticationService.logout();
    this.alertService.showWarning('shared.alerts.successMessages.idleLogout', 15000);
  }
}
